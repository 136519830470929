var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-rewards"},[_c('h5',[_vm._v(_vm._s(_vm.texts.product.selectedProducts))]),_c('CRow',{staticClass:"table-rewards-content"},[_c('CCol',{attrs:{"col":"12"}},[_c('CCard',{staticClass:"zq-card-table"},[_c('CCardBody',[_c('CDataTable',{staticClass:"zq--table",attrs:{"id":"rewardsResultTable","items":_vm.products,"fields":_vm.tableFields,"responsive":true,"border":_vm.isDefaultTheme,"hover":_vm.isDefaultTheme,"striped":_vm.isDefaultTheme,"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('TableLoader')]},proxy:true},{key:"rewardType",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.rewardType.key))])]}},{key:"customFields",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.customFields))])]}},{key:"created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.dateFormate(item.created)))])]}},{key:"pointInTime",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.dateFormate(item.pointInTime)))])]}},{key:"metadata",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.getMetaData(item.metadata)))])]}},{key:"period",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.period))])]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',{domProps:{"innerHTML":_vm._s(item.description )}})]}},{key:"icon",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.icon))])]}},{key:"spaceName",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.spaceName))])]}},{key:"entityType",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.entityType))])]}},{key:"entityId",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.entityId))])]}},{key:"translatableFields",fn:function(item){return [_c('td',[_vm._v(" "+_vm._s(_vm.getTranslatableFields(item))+" ")])]}},{key:"select-header",fn:function(){return [_c('div',{directives:[{name:"theme-select-all",rawName:"v-theme-select-all"}],staticClass:"position-relative zq--table-select-all"},[_c('ClCheckbox',{attrs:{"checkedProp":_vm.toggleFlag,"icon":"fa-angle-down"},on:{"handler":_vm.selectAll}})],1)]},proxy:true},{key:"select",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center select-td fixed-column-start",attrs:{"data-id":item.id}},[_c('ClCheckbox',{attrs:{"valueProp":item.id,"checkedProp":_vm.selectedRewards.indexOf(item.id) > -1},on:{"handler":_vm.checkRewards}})],1)]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"fixed-column-end",class:{'zq--active-fixed-column': _vm.activeToggleId === item.id},attrs:{"data-id":item.id}},[(!_vm.disabled)?_c('div',{staticClass:"delete-icon",on:{"click":function($event){return _vm.deleteProduct(item.id)}}},[_c('i',{staticClass:"fa fa-times"})]):_vm._e()])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }