<template>
  <div class="table-rewards">
    <!--  TAble  -->
    <h5>{{ texts.product.selectedProducts }}</h5>
    <CRow class="table-rewards-content">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody>
            <CDataTable
              id="rewardsResultTable"
              class="zq--table"
              :items="products"
              :fields="tableFields"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="loading"
            >
              <template #loading>
                <TableLoader/>
              </template>
              <template #rewardType="{item}">
                <td>{{ item.rewardType.key }}</td>
              </template>
              <template #customFields="{item}">
                <td>{{ item.customFields }}</td>
              </template>
              <template #created="{item}">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <template #pointInTime="{item}">
                <td>{{ dateFormate(item.pointInTime) }}</td>
              </template>
              <template #metadata="{item}">
                <td>{{ getMetaData(item.metadata) }}</td>
              </template>
              <template #period="{item}">
                <td>{{ item.period }}</td>
              </template>
              <template #description="{item}">
                <td v-html="item.description "></td>
              </template>
              <template #icon="{item}">
                <td>{{ item.icon }}</td>
              </template>
              <template #spaceName="{item}">
                <td>{{ item.spaceName }}</td>
              </template>
              <template #entityType="{item}">
                <td>{{ item.entityType }}</td>
              </template>
              <template #entityId="{item}">
                <td>{{ item.entityId }}</td>
              </template>
              <template #translatableFields="item">
                <td>
                  {{ getTranslatableFields(item) }}
                </td>
              </template>
              <!--            Select All-->
              <template #select-header class="text-center">
                <div class="position-relative zq--table-select-all" v-theme-select-all>
                  <ClCheckbox
                    :checkedProp="toggleFlag"
                    icon="fa-angle-down"
                    @handler="selectAll"
                  />
                </div>
              </template>
              <!--            Select-->
              <template #select="{ item }">
                <td :data-id="item.id" class="text-center select-td fixed-column-start">
                  <ClCheckbox
                    :valueProp="item.id"
                    :checkedProp="selectedRewards.indexOf(item.id) > -1"
                    @handler="checkRewards"
                  />
                </td>
              </template>
              <!--    Actions block          -->
              <template #actions="{ item }">
                <td
                  :data-id="item.id"
                  :class="{'zq--active-fixed-column': activeToggleId === item.id}"
                  class="fixed-column-end"
                >
                  <div v-if="!disabled" class="delete-icon" @click="deleteProduct(item.id)">
                    <i class="fa fa-times"></i>
                  </div>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {cloneDeep} from "lodash";
import {dateFormate, getMetaData, getTranslatableFields} from '@/utils';
import ClCheckbox from "@/shared/components/formComponents/ClCheckbox";
import {achievements} from "@/config/descriptions/achievements";
import TableLoader from "@/components/table/Loader";
import {competitionsTexts} from "@/config/pageTexts/competitions.json";
import { checkNullItem } from '@/helpers/checkNullItem';

export default {
  name: 'SelectedProductsTable',
  components: {
    TableLoader,
    ClCheckbox,
  },
  props: {
    productsData: Array,
    chosenRewards: Array,
    pageNumber: [String, Number],
    disabled: {
      type: Boolean,
      default: false
    },
    isPageNumber: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    isUpdate: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      deleteModal: false,
      toggleFlag: false,
      selectedId: null,
      products: [],
      selectedRewards: [],
      activeToggleId: null,
      descriptions: {
        rewards: achievements.list.create.rewards.rewards,
      },
      texts: {
        ...competitionsTexts
      },
      tableFields: [
        "id",
        "name",
        "adjustmentFactor",
        "productRefId",
        "tags",
        "description",
        "actionTypeAdjustmentFactors",
        "actions"
      ],
    }
  },
  computed: {
    ...mapGetters('products', ['fields', 'originalFields']),
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    },
    colorBtn() {
      switch (this.theme) {
        case 'main': {
          return ''
        }
        case 'default': {
          return 'dark'
        }
        default: {
          return ''
        }
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('products', ['handleFields', 'handleClearFilter']),
    initialize() {
      this.products = this.productsData.length ? checkNullItem(this.productsData) : [];
    },
    // FIX Z-INDEX ISSUE
    handleToggle(id) {
      this.activeToggleId = id;
    },
    getTranslatableFields({item}) {
      return getTranslatableFields(item);
    },
    dateFormate(val) {
      const date = dateFormate(val);
      return date === 'Invalid Date' ? '' : date
    },
    getMetaData(items) {
      return getMetaData(items)
    },
    checkRewards({event}) {
      const index = this.selectedRewards.indexOf(event.target.value);
      if (index > -1) {
        this.selectedRewards.splice(index, 1);
      } else {
        this.selectedRewards.push(event.target.value);
      }
    },
    selectAll() {
      const products = cloneDeep(this.products);
      this.toggleFlag = !this.toggleFlag;
      if (this.toggleFlag) {
        products.forEach(item => this.selectedRewards.push(item.id));
      } else {
        this.selectedRewards = [];
      }
    },
    showPreview(item) {
      this.$emit('setPage', {page: 'preview reward', data: item});
    },
    deleteProduct(id) {
      this.products = this.products.filter((item) => item.id !== id);
      this.$emit('updateProducts', this.products);
    },
    closeModal() {
      this.deleteModal = false;
      this.selectedId = null;
    },
    addFromTemplate() {
      this.$emit('addFromTemplate')
    },
    updateFields(val) {
      this.handleFields(val);
    },
    clearFilter() {
      this.handleClearFilter();
    },

  },
  watch: {
    selectedRewards: {
      deep: true,
      handler: function (val) {
        if (!val.length) this.toggleFlag = false;
      },
    },
    productsData: {
      deep: true,
      handler: function (val) {
        this.products = val;
      },
    },
  }
}
</script>

<style lang="scss">
@import "~@coreui/coreui/scss/coreui";

.table-rewards {
  height: 100%;
  padding: 0 1.5rem 0 1.5rem;
  margin-top: -1.5rem;
  .delete-icon {
    cursor: pointer;
    @include media-breakpoint-down(lg) {
      margin-left: 2rem;
    }
  }
  .rewardsUpdateHeader {
    padding-top: 1rem;
  }
  .rewards-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &--actions {
      // d-flex justify-content-end align-items-center flex-wrap
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      min-height: 42px;
    }
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
    .c-header-nav-items.dropdown {
      .btn {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  .table-rewards-subtitle {
    padding-top: 1rem;
    background: var(--zq-sub-bg);
  }
  .table-rewards-content {
    padding-top: 1rem;
    background: var(--zq-sub-bg);
    height: 100%;
  }
  .rewards-header--actions {
    .create-btn {
      margin-right: 8px;
    }
  }
  .selected-rewards {
    margin-top: 16px;
  }
}
</style>